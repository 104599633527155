import React from "react"
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import VideosSectionUpdate from "../../components/sections/VideosSectionUpdate";

const AccessoriesVideosPage = () => (
  <MainContainer>
    <HeadDataNew 
      title='Accessories Videos Page' 
      description='A list of Command Access Accessories videos.' 
    />

    <VideosSectionUpdate 
      title="Accessories"
      category="Accessories"
    />
  </MainContainer>
)

export default AccessoriesVideosPage